import React from 'react';
import { Link } from 'react-router-dom';
import { Input, Form, Button } from 'antd';
import { getClientId, getCode, getIdToken, getRefreshToken, getToken, removeCode, removeToken, removeIdToken, removeClientId, removeRefreshToken } from 'src/utils/local-storage';
import { requestToken, requestRefreshToken } from 'src/services/token-request';

import './index.css';

const Home = () => {
  // const [secret, setSecret] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  // CALL ALL CODE AND TOKEN
  const code: string | any = getCode(),
    token: string | any = getToken(),
    idToken: string | any = getIdToken(),
    refreshToken: string | any = getRefreshToken(),
    client: string | any = getClientId();
  // API CALL TO EXCHANGE CODE WITH ACCESS TOKEN
  const generateToken = async (values) => {
    try {
      setLoading(true)
      await requestToken(code, values.secret, client)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }
  // REFRESH TOKEN
  const refreshingToken = async (values) => {
    try {
      setLoading(true)
      await requestRefreshToken(refreshToken, values.secret)
      setLoading(false);
    } catch (error) {
      setLoading(false)
    }
  }
  // REMOVE ALL CREDENTIAL FROM LOCALHOST AND LOCAL STATE
  const wipeData = () => {
    // REMOVE LOCALHOST DATA
    removeClientId();
    removeCode();
    removeToken();
    removeIdToken();
    removeRefreshToken();
    window.location.reload();
  }
  return (
    <>
      <div className="home">
        {
          !code && (
            <Link to="/login">Simulate Login</Link>
          )
        }
        <Link to="/payment">Simulate Payment</Link>
        <Link to="/unlink">Simulate Unlink</Link>
      </div>
      {
        code && (
          <div className="action" style={{ marginBottom: 70 }}>
            <div className="action_code-form">
              <div className="action_code">
                <Form layout="vertical" name="token-exchange" onFinish={generateToken}>
                  <Form.Item
                    label="Code"
                    name="code"
                    initialValue={code}
                    rules={[{ required: true, message: 'Please input the code' }]}
                  >
                    <Input value={code} disabled />
                  </Form.Item>
                  <Form.Item
                    label="Secret"
                    name="secret"
                    rules={[{ required: true, message: 'Please input the secret' }]}
                  >
                    <Input />
                  </Form.Item>
                  <Button type="primary" htmlType="submit" loading={loading}>Generate Token</Button>
                </Form>
              </div>
            </div>
          </div>
        )
      }
      {
        refreshToken && (
          <div className="action">
            <div className="action_code-form">
              <div className="action_code">
                <Form layout="vertical" name="refresh-token" onFinish={refreshingToken}>
                  <Form.Item
                    label="Refresh Token"
                    name="refresh"
                    initialValue={refreshToken}
                    rules={[{ required: true, message: 'Please input the refresh token' }]}
                  >
                    <Input disabled />
                  </Form.Item>
                  <Form.Item
                    label="Secret"
                    name="secret"
                    rules={[{ required: true, message: 'Please input the secret' }]}
                  >
                    <Input />
                  </Form.Item>
                  <Button type="primary" htmlType="submit" loading={loading}>Refresh Token</Button>
                </Form>
              </div>
            </div>
          </div>
        )
      }
      {
        (token && idToken && refreshToken) && (
          <div className="action_token-display">
            <div className="action_token">
              <p>Token:</p>
              <Input.TextArea name="token" cols={50} rows={10} defaultValue={token} disabled />
            </div>
            <div className="action_token">
              <p>Id Token:</p>
              <Input.TextArea name="id-token" cols={50} rows={10} defaultValue={idToken} disabled />
            </div>
            <Button onClick={() => wipeData()} type="primary">Delete All Data</Button>
          </div>
        )
      }
    </>
  )
}

export default React.memo(Home);
