import React from 'react';
import { Input, Button } from 'antd';
import { saveClientId as saveToLocal } from 'src/utils/local-storage';

import './index.css';

const Login = () => {
  // LINK BUILDER PARAMETER
  const hydraLoginUrl = `${process.env.REACT_APP_AUTH_URL}/oauth2/auth`;
  const initialRedirectUrl = `${process.env.REACT_APP_URL}/callback`;
  const responseType = 'code';
  const state = '8jjushhsu18';
  const nonce = 'KjY3vA0ozj7TF4XXCezd5A';
  const devideId = '34567bbdnvbver';
  const initialScope = 'openid+offline+linkagepayment.pay+linkagepayment.check+linkagepayment.refund+linkagepayment.unlink';
  // STATE
  const [partnerClientId, setPartnerClientId] = React.useState('');
  const [audience, setAudience] = React.useState('');
  const [scope, setScope] = React.useState(initialScope);
  const [username, setUsername] = React.useState('');
  const [redirectUrl, setRedirectUrl] = React.useState(initialRedirectUrl);
  const [loginHint, setHint] = React.useState(undefined);
  // LINK BUILDER
  const linkBuilder = `${hydraLoginUrl}?redirect_uri=${redirectUrl}&client_id=${partnerClientId}&response_type=${responseType}&state=${state}&nonce=${nonce}&scope=${scope}&audience=${audience}&device_id=${devideId}&partner_username=${username}${loginHint ? '&login_hint=' + loginHint : ''}`;
  const saveClientId = () => {
    saveToLocal(partnerClientId);
  }
  const openNewWindow = () => {
    saveClientId();
    window.open(linkBuilder, '_blank', `targetwindow,toolbar=0,menubar=0,scrollbars=1,resizable=1,width=500,height=800`)
  }
  return (
    <div className="login">
      <div className="login_item">
        <div className="login_item-input">
          <label htmlFor="audience">Audience</label>
          <Input placeholder="dimii atau virgo" onChange={(event) => setAudience(event.target.value)} />
        </div>
        <div className="login_item-input">
          <label htmlFor="scope">Scope</label>
          <Input placeholder={initialScope} value={initialScope} onChange={(event) => setScope(event.target.value)} />
        </div>
        <div className="login_item-input">
          <label htmlFor="partner_client_id">Partner Client Id</label>
          <Input placeholder="contoh: alfagift.test atau qa.test" onChange={(event) => setPartnerClientId(event.target.value)} />
        </div>
        <div className="login_item-input">
          <label htmlFor="username">Partner Username</label>
          <Input placeholder="contoh: Yoga atau USER-001" onChange={(event) => setUsername(event.target.value)} />
        </div>
        <div className="login_item-input">
          <label htmlFor="redirect_url">Redirect Url</label>
          <Input placeholder={`contoh: ${initialRedirectUrl}`} value={redirectUrl} onChange={(event) => setRedirectUrl(event.target.value)} />
        </div>
        <div className="login_item-input">
          <label htmlFor="login-hint">No Handphone</label>
          <Input placeholder="081388272" onChange={(event) => setHint(event.target.value)} />
        </div>
      </div>
      {
        (partnerClientId && username && audience && scope && redirectUrl) && (
          <div className="login_item">
            <div className="login_item-title">1. Redirect Current Tab</div>
            <div className="login_item-content">
              <a href={linkBuilder} onClick={() => saveClientId()}>
                <Button type="primary">Click To Login</Button>
              </a>
            </div>
            <div className="login_item-title">2. Redirect With New Tab</div>
            <div className="login_item-content">
              <a href={linkBuilder} target="_blank" rel="noreferrer" onClick={() => saveClientId()}>
                <Button type="primary">Click To Login</Button>
              </a>
            </div>
            <div className="login_item-title">3. Opening New Window</div>
            <div className="login_item-content">
              <Button type="primary" onClick={() => openNewWindow()}>Click To Login</Button>
            </div>
          </div>
        )
      }
    </div>
  )
}

export default React.memo(Login);
