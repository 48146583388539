import React from 'react';
import { Form, Input, Button, Checkbox } from 'antd';

import { requestPayment } from 'src/services/payment';
import './index.css';
import { getToken } from 'src/utils/local-storage';
import { requestMerchantToken } from 'src/services/token-request';

const Payment = () => {
  const [canPay, setCanPay] = React.useState(false);
  const [merchantToken, setMerchantToken] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [iframe, setIframe] = React.useState(false);
  // GENERATE MECHANT TOKEN
  const onGenerateMerchantToken = async (values) => {
    console.log(values);
    try {
      setLoading(true);
      const response = await requestMerchantToken(values.client_id, values.secret, values.audience, values.scope);
      setMerchantToken(response.access_token);
      setCanPay(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }
  // SUBMIT
  const onSubmit = async (values) => {
    try {
      setLoading(true);
      const url = await requestPayment({
        amount: Number(values.amount),
        fee: Number(values.fee),
        tax: Number(values.tax),
        reference: values.reference,
        callback: !values.isCallback ? `${process.env.REACT_APP_URL}?partner_reference_code=${values.reference}` : undefined,
      });
      if (!values.isCallback) {
        // SIMULATING FORM SUBMISSION WITH DATA POPULATED WITH JAVASCRIPT
        // USE ONLY WHEN DEBUGGING LOCALLY WITH INSTANT PAYMENT RUNNING LOCALLY
        // const localRedirect = url.replace('https://payment-dev.virgoku.dev/', 'http://localhost:4000/');
        // USE ONLY WHEN DEBUGGING LOCALLY WITH INSTANT PAYMENT RUNNING LOCALLY
        // document['payment-form'].action = localRedirect;
        // USE ON DEV AND PRODUCTION
        document['payment-form'].action = url;
        (document as any).getElementById('token-input').value = getToken();
        // SIMULATING FORM SUBMISSION
        document['payment-form'].submit();
      } else {
        setIframe(true);
        // SIMULATING FORM SUBMISSION WITH DATA POPULATED WITH JAVASCRIPT
        // USE ONLY WHEN DEBUGGING LOCALLY WITH INSTANT PAYMENT RUNNING LOCALLY
        // const localRedirect = url.replace('https://payment-dev.virgoku.dev/', 'http://localhost:4000/');
        // USE ONLY WHEN DEBUGGING LOCALLY WITH INSTANT PAYMENT RUNNING LOCALLY
        // document['payment-form-iframe'].action = localRedirect;
        // USE ON DEV AND PRODUCTION
        document['payment-form-iframe'].action = url;
        (document as any).getElementById('token-input-iframe').value = getToken();
        // SIMULATING FORM SUBMISSION
        document['payment-form-iframe'].submit();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }
  // IFRAME POST MESSAGE CALLBACK
  const callback = (payload) => {
    console.log('balikan dari iframe', payload)
    const data = JSON.parse(payload.data);
    if (data.success) {
      window.location.href = `/?payment_nonce=${data.payment_nonce}`;
    } else {
      setIframe(false);
    }
  }
  window.addEventListener("message", callback, false);
  const initialScope = "openid offline linkagepayment.pay linkagepayment.check linkagepayment.refund linkagepayment.unlink";
  return (
    <>
      <div className="action">
        <Form layout="vertical" name="token-exchange" onFinish={onGenerateMerchantToken}>
          <Form.Item
            label="Client Id"
            name="client_id"
            rules={[{ required: true, message: 'Please input the client id' }]}
          >
            <Input placeholder="qa.test" />
          </Form.Item>
          <Form.Item
            label="Secret"
            name="secret"
            rules={[{ required: true, message: 'Please input the secret' }]}
          >
            <Input placeholder="secret" />
          </Form.Item>
          <Form.Item
            label="Scope"
            name="scope"
            rules={[{ required: true, message: 'Please input the scope' }]}
          >
            <Input placeholder={initialScope} value={initialScope} />
          </Form.Item>
          <Form.Item
            label="Audience"
            name="audience"
            rules={[{ required: true, message: 'Please input the audience' }]}
          >
            <Input placeholder="virgo" />
          </Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>Generate Merchant Token</Button>
        </Form>
        <div className="action_token">
          <p>Merchant Token, store this to verify payment later:</p>
          <Input.TextArea name="token" cols={50} rows={10} value={merchantToken} disabled />
        </div>
        {
          canPay && (
            <Form layout="vertical" name="token-exchange" onFinish={onSubmit}>
              <p className="payment-title">Payment</p>
              <Form.Item
                label="Amount"
                name="amount"
                rules={[{ required: true, message: 'Please input the amount' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Fee"
                name="fee"
                rules={[{ required: true, message: 'Please input the fee' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Tax"
                name="tax"
                rules={[{ required: true, message: 'Please input the tax' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Reference"
                name="reference"
                rules={[{ required: true, message: 'Please input the reference' }]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="isCallback"
                valuePropName="checked"
              >
                <Checkbox>Open using iframe</Checkbox>
              </Form.Item>
              <Button type="primary" htmlType="submit" loading={loading}>Pay</Button>
            </Form>
          )
        }
        <form name="payment-form" hidden method="post">
          <input id="token-input" type="text" hidden name="token" />
        </form>
        <form name="payment-form-iframe" hidden method="post" target="output_iframe">
          <input id="token-input-iframe" type="text" hidden name="token" />
        </form>
        {
          iframe && (
            <iframe name="output_iframe" title="instant-payment" frameBorder="0"></iframe>
          )
        }
      </div>
    </>
  )
}

export default React.memo(Payment)
