import axios from 'axios';
import { notification } from 'antd';
import { getToken } from 'src/utils/local-storage';

interface IParameter {
  amount: number;
  fee: number;
  tax: number;
  reference: string;
  callback?: string;
}

export const requestPayment = async ({ amount, fee, tax, reference, callback }: IParameter) => {
  try {
    const response = await axios({
      method: 'POST',
      baseURL: process.env.REACT_APP_API_URL,
      url: '/r/transaction/payment',
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Authorization': `Bearer ${getToken()}`,
        'DeviceId': '34567bbdnvbver',
      },
      data: {
        amount,
        description: "description",
        fee,
        partner_payment_timestamp: 1611389990,
        partner_reference_code: reference,
        tax,
        transaction_type: 301,
        callback_url: callback,
      },
    });
    // window.location.href = response.request.responseURL;
    return response.request.responseURL;
  } catch (error) {
    console.log(error)
    notification.error({
      message: 'Error',
    });
    throw error
  }
}

export const requestPaymentFetch = async ({ amount, fee, tax, reference, callback }: IParameter) => {
  fetch(process.env.REACT_APP_API_URL + '/r/transaction/payment', {
    method: 'POST',
    redirect: 'follow',
    // credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'Authorization': `Bearer ${getToken()}`,
      'DeviceId': '34567bbdnvbver',
    },
    body: JSON.stringify({
      amount,
      description: "description",
      fee,
      partner_payment_timestamp: 1611389990,
      partner_reference_code: reference,
      tax,
      transaction_type: 301,
      callback_url: callback,
    }),
  })
    .then(res => {
      console.log(res);
      return res;
    })
    .catch(res => {
      return res;
    })
}
